import { sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Committee',
      flex: 1,
      sortable: false,
      sort: 'asc',
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.parent?.name}</div>`)
          .join('');
      },
    },
    {
      headerName: 'Name',
      flex: 1,
      sortable: false,
      sort: 'asc',
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p[p.parent?.entity_type].name}</div>`)
          .join('');
      },
    },
    {
      headerName: 'Chair',
      flex: 1,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}

export function handleAffiliates(affiliates) {
  let collection = [];

  // Duplicate affiliates for each position
  affiliates.forEach((a) => {
    a.positions.forEach((p) => {
      const clonedA = JSON.parse(JSON.stringify(a));
      clonedA.positions = [p];
      collection.push(clonedA);
    });
  });

  // Remove TCs that does not have CCs
  collection = collection.filter((a1) => {
      // Affiliate Position
      const a1p = a1.positions[0];

      // If TC
      if (a1.positions[0].technicalCommittee) {
        // Find parent CC
        const index = collection.findIndex((a2) => {
          const a2p = a2.positions[0];
          return a2p.coordinatingCommittee?.id === a1p.technicalCommittee?.coordinating_committee_id;
        });

        return index > -1;
      }

      return a1;
  });

  // Sort CC and TCs by their number
  collection.sort((a, b) => {
    const pA = a.positions[0];
    const pB = b.positions[0];

    // Determine sort keys for primary sorting (CC vs TC)
    const keyA = pA.coordinatingCommittee ? pA.coordinatingCommittee.id : pA.technicalCommittee.coordinating_committee_id;
    const keyB = pB.coordinatingCommittee ? pB.coordinatingCommittee.id : pB.technicalCommittee.coordinating_committee_id;

    // Sort CCs vs TCs
    if (keyA !== keyB) {
      return keyA - keyB;
    }

    // Sort CCs
    if (pA.coordinatingCommittee && pB.technicalCommittee) {
      return -1; // CC first
    } if (pA.technicalCommittee && pB.coordinatingCommittee) {
      return 1; // CC first
    }

    // Sort TCs
    if (pA.technicalCommittee && pB.technicalCommittee) {
      return pA.technicalCommittee.number - pB.technicalCommittee.number;
    }

    return 0;
  });

  // Set showLine
  collection.forEach((a, index) => {
      a.showLine = false;
      if (a.positions[0].coordinatingCommittee) {
        const nextAffiliate = collection[index - 1];
        if (!nextAffiliate || nextAffiliate.positions[0].technicalCommittee) {
          a.showLine = true;
        }
      }
  });

  return collection;
}

export function gridRowStyle(params) {
  if (params.data.showLine === true) {
    return {
      borderTop: '2px solid black',
    };
  }

  return undefined;
}
